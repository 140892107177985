/*
  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
  SPDX-License-Identifier: MIT-0 
*/

export default {
  setVinNo(state: { vinNo: string }, payload: string): void {
    state.vinNo = payload;
  },
  setTypeName(state: { typeName: string }, payload: string): void {
    state.typeName = payload;
  },
  setSaleModel(state: { saleModel: string }, payload: string): void {
    state.saleModel = payload;
  },
  setGrade(state: { grade: string }, payload: string): void {
    state.grade = payload;
  },
  setEngine(state: { engine: string }, payload: string): void {
    state.engine = payload;
  },
  setTurbo(state: { turbo: string }, payload: string): void {
    state.turbo = payload;
  },
  setDriveSys(state: { driveSys: string }, payload: string): void {
    state.driveSys = payload;
  },
  setTransmission(state: { transmission: string }, payload: string): void {
    state.transmission = payload;
  },
  setEDB(state: { EDB: string }, payload: string): void {
    state.EDB = payload;
  },
  setAirbag(state: { airbag: string }, payload: string): void {
    state.airbag = payload;
  },
  setOnsaleDate(state: { onsaleDate: string }, payload: string): void {
    state.onsaleDate = payload;
  },
  setNotFoundVinNo(state: { notFoundVinNo: string }, payload: string): void {
    state.notFoundVinNo = payload;
  },
};
