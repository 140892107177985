// import AWSCredential from "@/types/AWSCredential";
import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'
import authModule from "@/store/auth/index";

type State = {
  isLoading: boolean;
};

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state() {
    return {
      isLoading: false,
    };
  },
  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    setIsLoading(context, payload) {
      context.commit("setIsLoading", payload);
    },
  },
  modules: {
    authModule,
  },
  plugins: [createPersistedState({
  }
  )]
});

export const useStore = (): Store<State> => {
  return baseUseStore(key);
};
