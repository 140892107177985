import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router/";
import { key, store } from "@/store/store";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@/assets/css/main.css";

createApp(App).use(store, key).use(router).mount("#app");
