/*
  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
  SPDX-License-Identifier: MIT-0 
*/
import mutations from "@/store/auth/mutations";
import actions from "@/store/auth/actions";
import getters from "@/store/auth/getters";
import StoreAuth from "@/types/StoreAuth";

export default {
  state(): StoreAuth {
    return {
      vinNo: "",
      typeName: "",
      saleModel: "",
      grade: "",
      engine: "",
      turbo: "",
      driveSys: "",
      transmission: "",
      EDB: "",
      airbag: "",
      onsaleDate: "",
      notFoundVinNo: "",
    };
  },
  mutations,
  actions,
  getters,
};
