/*
  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
  SPDX-License-Identifier: MIT-0 
*/

//timeout in seconds
// let seconds_timeout = 3600;

export default {

  setVinNo(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setVinNo", payload);
  },
  setTypeName(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setTypeName", payload);
  },
  setSaleModel(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setSaleModel", payload);
  },
  setGrade(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setGrade", payload);
  },
  setEngine(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setEngine", payload);
  },
  setTurbo(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setTurbo", payload);
  },
  setDriveSys(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setDriveSys", payload);
  },
  setTransmission(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setTransmission", payload);
  },
  setEDB(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setEDB", payload);
  },
  setAirbag(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setAirbag", payload);
  },
  setOnsaleDate(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setOnsaleDate", payload);
  },
  setNotFoundVinNo(
    context: { commit: (arg0: string, arg1: string) => void },
    payload: string
  ): void {
    context.commit("setNotFoundVinNo", payload);
  },

};
