/*
  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
  SPDX-License-Identifier: MIT-0 
*/

export default {

  vinNo(state: { vinNo: string }): string {
    return state.vinNo;
  },
  typeName(state: { typeName: string }): string {
    return state.typeName;
  },
  saleModel(state: { saleModel: string }): string {
    return state.saleModel;
  },
  grade(state: { grade: string }): string {
    return state.grade;
  },
  engine(state: { engine: string }): string {
    return state.engine;
  },
  turbo(state: { turbo: string }): string {
    return state.turbo;
  },
  driveSys(state: { driveSys: string }): string {
    return state.driveSys;
  },
  transmission(state: { transmission: string }): string {
    return state.transmission;
  },
  EDB(state: { EDB: string }): string {
    return state.EDB;
  },
  airbag(state: { airbag: string }): string {
    return state.airbag;
  },
  onsaleDate(state: { onsaleDate: string }): string {
    return state.onsaleDate;
  },
  notFoundVinNo(state: { notFoundVinNo: string }): string {
    return state.notFoundVinNo;
  },
};
