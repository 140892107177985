// import CognitoAuth from "@/services/CognitoAuthService";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Notes",
    component: () => import("../components/sgre/Notes.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/SearchGrade",
    name: "SearchGrade",
    component: () => import("../components/sgre/SearchGrade.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Result",
    name: "Result",
    component: () => import("../components/sgre/Result.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/NotFound",
    name: "NotFound",
    component: () => import("../components/sgre/NotFound.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
